<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio evento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">

                <div class="event">
                    <div v-if="event.events_title" class="event_title">
                        <div class="event_name">{{ event.events_title }}</div>
                    </div>

                    <div class="title">
                        <div class="event_date">{{ formatEventDate(event.events_date_start, event.events_date_end) }}</div>
                        <div class="event_time">
                            <div>{{ timeFormat(event.events_date_start) }} - {{ timeFormat(event.events_date_end) }}</div>
                        </div>
                    </div>

                    <div v-if="event.events_google_calendar" class="field_note">
                        <div class="title">Google calendar</div>
                        <div class="value">
                            {{ event.google_calendars_list_google_id }}
                        </div>
                    </div>

                    <div v-if="event.events_description" class="field_note">
                        <div class="title"></div>
                        <div class="value">
                            {{ event.events_description }}
                        </div>
                    </div>

                    <div v-if="event.events_image" class="field_note">
                        <div class="title"></div>
                        <div class="value">
                            <img :src="setImageUrl(event.events_image)" alt="" style="border-radius: 8px;">
                        </div>
                    </div>

                    <div class="field_note">
                        <div class="button_container_calendar">
                            <add-to-calendar-button
                                :name="event.events_title"
                                :description="event.events_description"
                                :location="event.events_address"
                                :startDate="formatDate(event.events_date_start)"
                                :endDate="formatDate(event.events_date_end)"
                                :startTime="formatTime(event.events_date_start)"
                                :endTime="formatTime(event.events_date_end)"
                                timeZone="Europe/Rome"
                                options="'Apple','Google'"
                                hideBranding
                                buttonsList
                                lightMode="bodyScheme"
                                styleLight="--btn-background: rgb(219 234 254); --btn-text: rgb(37 99 235); --btn-border: rgb(37 99 235); --btn-padding-x: 16px; --btn-padding-y: 8px;"
                            >
                            </add-to-calendar-button>
                        </div>
                    </div>

                    <!-- Dati cliente collegato -->
                    <div v-if="event.events_customer_id" class="customer_data">
                        <div class="title">
                            {{ event.customers_full_name }}
                        </div>
                        <div class="field">
                            <div class="title">Telefono</div>
                            <div v-if="event.customers_mobile" class="value">
                                <a :href="`tel:${event.customers_mobile}`" target="_blank" class="mobile_link">{{ event.customers_mobile }}</a>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="field">
                            <div class="title">Email</div>
                            <div v-if="event.customers_email" class="value">
                                <a :href="`mailto:${event.customers_email}`" target="_blank" class="mobile_link">{{ event.customers_email }}</a>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="field_note field_note_address">
                            <div class="title">Indirizzo</div>
                            <div class="value">
                                <a
                                    :href="`https://maps.google.com/?q=${event.customers_city} ${event.customers_address} ${event.customers_zip_code}`"
                                    target="_blank"
                                    class="mobile_link mobile_link_maps"
                                >
                                    {{ formattedAddress() }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!event.events_customer_id && event.events_address" style="width:100%;">
                        <div class="field_note field_note_address">
                            <div class="title">Indirizzo</div>
                            <div class="value">
                                <a
                                    :href="`https://maps.google.com/?q=${event.events_address}`"
                                    target="_blank"
                                    class="mobile_link mobile_link_maps"
                                >
                                    {{ event.events_address }}
                                </a>
                            </div>
                        </div>
                    </div>


                    <div v-if="event.events_requires_confirmation && event.events_requires_confirmation == '1' && !confirmationSend" style="margin-top: 32px; width: 100%;">
                        <div style="text-align: center;">Parteciparai? Sì, ancora non lo so oppure no</div>

                        <div class="delete_button_container" style="display: flex; gap: 16px;">
                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(1)"         
                                    :class="{ active: selectedConfirmation === 1 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 1 ? 0.4 : 1 }"
                                    class="btn_partecipate confirm" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'thumbs-up']" style="color: rgb(37 99 235); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(37 99 235); font-size: 20px;">{{ event.count_yes }}</div>
                                </button>
                            </div>

                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(2)"         
                                    :class="{ active: selectedConfirmation === 2 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 2 ? 0.4 : 1 }"
                                    class="btn_partecipate maybe" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'question']" style="color: rgb(234 88 12); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(234 88 12); font-size: 20px;">{{ event.count_maybe }}</div>
                                </button>
                            </div>

                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(3)"         
                                    :class="{ active: selectedConfirmation === 3 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 3 ? 0.4 : 1 }"
                                    class="btn_partecipate not_confirm" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'thumbs-down']" style="color: rgb(220 38 38); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(220 38 38); font-size: 20px;">{{ event.count_no }}</div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="event.events_requires_confirmation && event.events_requires_confirmation == '1' && confirmationSend" style="margin-top: 32px; width: 100%;">
                        <div style="text-align: center;">Modifica la tua risposta</div>

                        <div class="delete_button_container" style="display: flex; gap: 16px;">
                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(1)"         
                                    :class="{ active: selectedConfirmation === 1 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 1 ? 0.4 : 1 }"
                                    class="btn_partecipate confirm" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'thumbs-up']" style="color: rgb(37 99 235); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(37 99 235); font-size: 20px;">{{ event.count_yes }}</div>
                                </button>
                            </div>

                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(2)"         
                                    :class="{ active: selectedConfirmation === 2 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 2 ? 0.4 : 1 }"
                                    class="btn_partecipate maybe" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'question']" style="color: rgb(234 88 12); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(234 88 12); font-size: 20px;">{{ event.count_maybe }}</div>
                                </button>
                            </div>

                            <div class="button_container">
                                <button 
                                    @click="setConfirmation(3)"         
                                    :class="{ active: selectedConfirmation === 3 }"
                                    :style="{ opacity: selectedConfirmation && selectedConfirmation !== 3 ? 0.4 : 1 }"
                                    class="btn_partecipate not_confirm" 
                                    style="height: 40px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'thumbs-down']" style="color: rgb(220 38 38); font-size: 20px;" />
                                    <div class="answer_container" style="color: rgb(220 38 38); font-size: 20px;">{{ event.count_no }}</div>

                                </button>
                            </div>
                            
                        </div>
                    </div>

                    <!-- <div class="delete_button_container">
                        <button @click="deleteEvent()" class="btn_delete_event">Elimina evento</button>
                    </div> -->
                </div>

                <!-- Elenco partecipanti -->
                <div v-if="event.partecipants && event.partecipants.length > 0" class="event event_list">
                    <div class="event_title">
                        <div class="event_name">Elenco persone</div>
                    </div>

                    <div v-for="(partecipant, index) in event.partecipants" :key="index" class="single_participant">
                        <span>{{ partecipant.users_first_name }} {{ partecipant.users_last_name }}</span>
                        <span
                            :style="{ color: getPartecipationColor(partecipant.events_participants_users_partecipate) }"
                            style="font-weight: 600;"
                        >
                            {{ partecipant.events_participants_users_partecipate_value }}
                        </span>
                    </div>
                </div>


            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
    getPlatforms
} from "@ionic/vue";

import { defineComponent, ref, onMounted } from "vue";
import { alertController } from "@ionic/vue";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";
import 'add-to-calendar-button';

import { LOCALSTORAGE_KEY } from "@/custom_config";


import { openToast } from "@/services/toast";
import { dateFormat, timeFormat } from "@/services/utils";

import apiCalendario from "@/services/calendario";

export default defineComponent({
    name: "EvetDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        // console.log(props.data);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const userData = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));

        const event = ref([]);
        event.value = { ...props.data };
        //console.log(event.value);


        const isAndroid = ref(false);
        const isIos = ref(false);
        const platforms = getPlatforms();
        if (platforms.includes("android")) {
            isAndroid.value = true;
        } else if (platforms.includes("ios") || platforms.includes("iphone")) {
            isIos.value = true;
        }


        function setImageUrl(path) {
            if (path && path != '') {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
            }
        }

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = (deleted = false) => {
            modalController.dismiss(deleted);
        };

        const formatEventDate = (startDate, endDate) => {
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth() && start.getDate() === end.getDate()) {
                return dateFormat(startDate);
            } else {
                return `${dateFormat(startDate)} - ${dateFormat(endDate)}`;
            }
        };

        const formattedAddress = () => {
            const { customers_address, customers_city, customers_province } = event.value;

            let formatted = "";

            if (customers_address && customers_address.trim() !== "") {
                formatted += customers_address + ", ";
            }
            if (customers_city && customers_city.trim() !== "") {
                formatted += customers_city + " (";
            }
            if (customers_province && customers_province.trim() !== "") {
                formatted += customers_province + ")";
            }

            return formatted;
        };

        async function handleCancellation() {
            if (event.value.events_id) {
                let deleted = false;

                const res = await apiCalendario.deleteEvent(event.value.events_id);

                if (res.status === 200 && res.data.status === 0) {
                    deleted = true;
                    closeModal(deleted);
                } else {
                    openToast("Si è verificato un durante l'eliminazione dell'evento", "toast_danger");
                    closeModal(deleted);
                }
            } else {
                openToast("Non è stato possibile riconscere l'evento da eliminare", "toast_danger");
            }
        }

        async function showAlert() {
            const alert = await alertController.create({
                header: "Elimina evento",
                message: "Vuoi eliminare definitivamente questo evento?",
                backdropDismiss: false,
                buttons: [
                    {
                        text: "Annulla",
                        handler: () => {
                            // Close alert
                        },
                    },
                    {
                        text: "Elimina",
                        handler: () => {
                            handleCancellation();
                        },
                    },
                ],
            });
            return alert.present();
        }

        async function deleteEvent() {
            await showAlert();
        }


        const confirmationSend = ref(false);
        const selectedConfirmation = ref(null);
        async function setConfirmation(status) {
            try {
                const res = await apiCalendario.setConfirmation(event.value.events_id, userID, status);

                if (res.status === 200 && res.data.status === 0) {
                    openToast(res.data.txt, "toast_success");
                    confirmationSend.value = true;

                    // Trova la risposta precedente dell'utente
                    const existingParticipant = event.value.partecipants.find(p => p.users_id === userID);
                    console.log(existingParticipant);
                    

                    if (existingParticipant) {
                        // Decrementa il contatore della vecchia risposta
                        if (existingParticipant.events_participants_users_partecipate_id == 1) {
                            event.value.count_yes--;
                        } else if (existingParticipant.events_participants_users_partecipate_id == 2) {
                            event.value.count_maybe--;
                        } else if (existingParticipant.events_participants_users_partecipate_id == 3) {
                            event.value.count_no--;
                        }

                        // Aggiorna la risposta dell'utente
                        existingParticipant.events_participants_users_partecipate = status.toString();
                        existingParticipant.events_participants_users_partecipate_id = status.toString();
                        existingParticipant.events_participants_users_partecipate_value = 
                            status === 1 ? "Si" : status === 2 ? "Forse" : "No";
                    } else {
                        // Se l'utente non è presente, lo aggiungo alla lista
                        const currentUser = {
                            events_id: event.value.events_id,
                            users_id: userID,
                            events_participants_users_id: userID,
                            events_participants_users_partecipate: status.toString(),
                            events_participants_users_partecipate_id: status.toString(),
                            events_participants_users_partecipate_value: 
                                status === 1 ? "Si" : status === 2 ? "Forse" : "No",
                            users_first_name: userData.dipendenti_nome,
                            users_last_name: userData.dipendenti_cognome,
                            users_email: userData.dipendenti_email
                        };
                        event.value.partecipants.unshift(currentUser);
                    }

                    // Incrementa il contatore della nuova risposta
                    if (status === 1) {
                        event.value.count_yes++;
                    } else if (status === 2) {
                        event.value.count_maybe++;
                    } else if (status === 3) {
                        event.value.count_no++;
                    }

                    selectedConfirmation.value = status;
                } else {
                    openToast(res.data.txt, "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante il salvataggio della risposta", "toast_danger");
            }
        }


        function checkConfirmation() {
            if (
                event.value.events_participants &&
                Object.keys(event.value.events_participants).some(participantID => participantID == userID)
            ) {
                confirmationSend.value = true;
            }

            // Recupera la risposta selezionata dell'utente
            const existingParticipant = event.value.partecipants.find(p => p.users_id === userID);

            if (existingParticipant) {
                confirmationSend.value = true;
                selectedConfirmation.value = parseInt(existingParticipant.events_participants_users_partecipate);
            } else {
                selectedConfirmation.value = null; // Nessuna risposta selezionata
            }
        }


        function getPartecipationColor(value) {
            const colors = {
                1: "rgb(37 99 235)",  // Sì
                2: "rgb(234 88 12)",  // Forse
                3: "rgb(220 38 38)"   // No
            };
            return colors[value] || "inherit"; // Default se non corrisponde
        }


        // Calendar helpers
        const formatDate = (datetime) => {
            return moment(datetime).format("YYYY-MM-DD");
        };

        const formatTime = (datetime) => {
            return moment(datetime).format("HH:mm");
        };



        onMounted(() => {
            checkConfirmation();
        })

        return {
            event,
            setImageUrl,
            dateFormat,
            timeFormat,
            arrowBackOutline,
            closeModal,
            deleteEvent,
            formatEventDate,
            formattedAddress,
            // Partecipazione ed elenco partecipanti
            setConfirmation,
            confirmationSend,
            selectedConfirmation,
            getPartecipationColor,
            // Calendar helpers
            formatDate,
            formatTime,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new event btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.event {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.event_customer {
    margin-top: 24px;
}
.event > .event_title,
.event .customer_data > .title {
    width: 100%;
    padding: 8px;
    color: #121212;
    text-align: center;
}
.event > .title {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}
.event .event_title .event_name {
    font-size: 20px;
    font-weight: 500;
}
.event .title .event_date {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.event .title .event_time {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.event .customer_data {
    width: 100%;
    margin-top: 24px;
    border-top: 0.5px solid #7676803d;
}
.event .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 12px 6px;*/
    padding: 12px 0px;
    /*margin-bottom: 10px;*/
    border-bottom: 1px solid #e2e8f0;
}
.event .customer_data .field {
    border-bottom: none;
}
.event .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 0px;
    border-bottom: none;
}
.event .field_ricevuta {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_note .title,
.field_ricevuta .title {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
}
.field_ricevuta .title {
    margin-bottom: 8px;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value,
.field_ricevuta .value {
    font-size: 14px;
}

.value .badge {
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge.badge_inserita {
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge.badge_rimborsata {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge.badge_rifiutata {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
.value .badge.badge_accettata {
    background-color: rgb(219 234 254);
    color: rgb(59 130 246);
}
ion-button {
    --color: #ffffff;
}

.delete_button_container {
    width: 100%;
    margin-top: 24px;
}

.btn_delete_event {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    color: rgb(220 38 38);
    background-color: rgb(254 226 226);
}

.btn_partecipate {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}
.btn_partecipate.confirm {
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
    margin-bottom: 12px;
}
.btn_partecipate.maybe {
    color: rgb(234 88 12);
    background-color: rgb(255 237 213);
    margin-bottom: 12px;
}
.btn_partecipate.not_confirm {
    color: rgb(220 38 38);
    background-color: rgb(254 226 226);
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}
.mobile_link_maps {
    width: 100%;
    display: block;
    text-align: right;
}

.event_list {
    margin-top: 24px;
}

.event_list .single_participant {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 14px;
}

.button_container {
    width: 100%;
}

.button_container_calendar {
    width: 100%;
    display: flex;
    justify-content: center;
}

#atcb-reference {
    display: none;
}

add-to-calendar-button#css-part-example::part(atcb-button),
  #atcb-btn-css-part-example-modal-host::part(atcb-button) {
    background-color: #264f3c;
    color: #fff;
  }
  /* or, for all buttons and modals:
  .add-to-calendar::part(atcb-button) {
    background-color: #264f3c;
    color: #fff;
  }
  */
</style>
