
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons, onIonViewWillEnter } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";
import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat, timeFormat } from "@/services/utils";

import apiAppuntamenti from "@/services/appuntamenti_squadre";
import apiCalendario from "@/services/calendario";


import EventDetail from "@/components/calendario/EventDetail.vue";

export default {
    name: "Calendario2",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const events = ref([]);
        const eventsToShow = ref([]);

        // Get updated first and last days based on calendar interaction
        const startDate = ref(moment().startOf('month').format('YYYY-MM-DD'));
        const endDate = ref(moment().endOf('month').format('YYYY-MM-DD'));


        const openMenu = () => {
            menuController.open("app-menu");
        };


        /**
         * ! Open event detail page
         */
         async function openDetailModal(event) {
            let currentEventId = event.events_id;

            const modal = await modalController.create({
                component: EventDetail,
                componentProps: {
                    data: event,
                },
            });

            modal.onDidDismiss().then((detail) => {
                loadData();
                //console.log(detail);
                if (detail.data) {
                    const deleted = detail.data;

                    if (deleted && currentEventId) {
                        //Filtro array event togliendo quello eliminato
                        events.value = events.value.filter((event) => event.events_id != currentEventId);
                        //Filtro array eventi visualizzati togliendo quello eliminato
                        eventsToShow.value = eventsToShow.value.filter((event) => event.events_id != currentEventId);

                        openToast("Evento eliminato con successo", "toast_success");
                    } else {
                        openToast("Si è verificato un errore durante l'eliminazione dell'evento", "toast_danger");
                    }
                }
                // Reset current event
                currentEventId = null;
            });

            return modal.present();
        }



        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const setAttributes = computed(() => {
            /* return events.value.map((event) => ({
                key: `event.${event.events_id}`,
                highlight: {
                    color: moment(event.events_date_start).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD") ? "green" : "blue",
                    fillMode: "solid",
                },
                dates: event.events_date_start,
                customData: event,
            })); */
            const attributes = events.value.map((event) => ({
                key: `event.${event.events_id}`,
                highlight: {
                    color: moment(event.events_date_start).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD") ? "green" : "blue",
                    fillMode: "solid",
                },
                dates: event.events_date_start,
                customData: event,
            }));

            const todayFormatted = moment(new Date()).format("YYYY-MM-DD");
            // Controlla se esiste già un evento per oggi
            const hasTodayEvent = attributes.some(attr => moment(attr.dates).format("YYYY-MM-DD") === todayFormatted);

            // Se non ci sono eventi per oggi, aggiungi la data corrente evidenziata
            if (!hasTodayEvent) {
                attributes.push({
                    key: "today",
                    highlight: {
                        color: "green",
                        fillMode: "solid",
                    },
                    dates: todayFormatted,
                    customData: null, // Nessun evento associato
                });
            }

            return attributes;
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
         const dayClicked = (day) => {
            selectedDay.value = day;
            eventsToShow.value = [];

            eventsToShow.value = day.attributes.length === 0
                ? []
                : day.attributes
                    .map((appuntamento) => appuntamento.customData)
                    .filter(evento => evento !== null); // Rimuove elementi nulli
        };


        /**
         * 
         * ! Ad ogni cambio mese richiedo i rapportini di quello corrente
         * 
         */
        async function loadData() {
            events.value = [];
            eventsToShow.value = [];
            //console.log(startDate, endDate);
            try {                
                const res = await apiCalendario.getEvents(userID, startDate.value, endDate.value);

                if(res.status === 200 && res.data.status === 0) {
                    events.value = res.data.data;
                    //Popolo array degli eventi da mostrare con quelli di oggi per vederli subito
                    const todayApp = res.data.data.filter((el) => 
                        moment(el.events_date_start).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                    );
                    eventsToShow.value = todayApp;
                } else {
                    openToast("Si è verificato un errore durante la richiesta degli eventi", "toast_danger");
                }
            } catch (error) {
                events.value = [];
                openToast("Si è verificato un errore durante la richiesta degli eventi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        const loadCurrentMonthData = async (page) => {
            const currentMonth = page.month;
            const currentYear = page.year;

            startDate.value = moment([currentYear, currentMonth - 1]).startOf("month").format("YYYY-MM-DD");
            endDate.value = moment([currentYear, currentMonth - 1]).endOf("month").format("YYYY-MM-DD");
            
            await loadData();

            const isCurrentMonth = moment().month() + 1 === currentMonth && moment().year() === currentYear;
            if (isCurrentMonth) {
                if (!selectedDay.value || !moment(selectedDay.value.id).isBetween(startDate.value, endDate.value, null, '[]')) {
                    selectedDay.value = { id: moment().format("YYYY-MM-DD") };
                }
                eventsToShow.value = events.value.filter(event =>
                    moment(event.events_date_start).format("YYYY-MM-DD") === selectedDay.value.id
                );
            }
        };

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            //loadAppuntamenti();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            dateFormat,
            timeFormat,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            eventsToShow,
            loadCurrentMonthData,
            loadData
        };
    },
};
